<template>
  <form>
    <div class="form-group">
      <label for="select_cat">Select Category</label>
      <select
          class="form-control"
          id="select_cat"
          v-model="form.category_id"
      >
        <option
            v-for="runnerCategory in runnerCategories"
            :key="runnerCategory.id"
            :value="runnerCategory.id"
        >
          {{ runnerCategory.name }}
        </option>
      </select>
      <small v-if="errors && errors.name" class="text-danger">
        {{ errors.name[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catDescription">Title</label>
      <input
          class="form-control"
          id="catTitle"
          v-model="form.title"
      />
      <small v-if="errors && errors.title" class="text-danger">
        {{ errors.title[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catDescription">Description</label>
      <textarea
          class="form-control"
          id="body"
          rows="3"
          v-model="form.body"
      ></textarea>
      <small v-if="errors && errors.body" class="text-danger">
        {{ errors.body[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catDescription">Phone number of Contact Person</label>
      <input
          class="form-control"
          id="catDescription"
          type="number"
          v-model="form.contact"
      />
      <small v-if="errors && errors.contact" class="text-danger">
        {{ errors.contact[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catDescription">Due Date</label>
      <input
          class="form-control"
          id="catDate"
          type="date"
          data-date-format="mm/dd/yyyy"
          v-model="form.due_date"
      />
      <small v-if="errors && errors.due_date" class="text-danger">
        {{ errors.due_date[0] }}
      </small>
    </div>

    <button
        v-if="auth.isAuthenticated"
        :disabled="isLoading"
        type="submit"
        class="btn btn-primary ls-submit-button"
        @click.prevent="onSubmit()"
    >
      {{ isLoading ? "Processing..." : "Submit" }}
      <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
      <div
          v-if="isLoading"
          class="spinner-border text-light"
          role="status"
      ></div>
    </button>
    <router-link v-else :to="{ name: 'login' }">
      <button type="submit" class="btn btn-primary ls-submit-button">
        Submit
      </button>
    </router-link>
  </form>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
  name: "RunnerRequestForm",
  props: {
    runnerRequestId: Number
  },
  data() {
    return {
      runnerCategories: [],
      runnerRequest: null,
      form: {
        title: "",
        body: "",
        category_id: "",
        contact: "",
        due_date: "",
      },
      isLoading: false,
      errors: null
    };
  },

  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },

  mounted() {
    this.getRunnersCategories();
    if (this.auth.isAuthenticated) {
      this.form.contact = this.auth.user.phone;
    }
    if (this.runnerRequestId) {
       this.getRunnersRequest()
    }

    // const dateEl = $("#catDate");
    // dateEl.datepicker({
    //   weekStart: 1,
    //   daysOfWeekHighlighted: "6,0",
    //   autoclose: true,
    //   todayHighlight: true,
    // });
    // dateEl.datepicker("setDate", new Date());
    // this.runnerRequest.due_date = new Date().toLocaleDateString("en-US");
  },

  methods: {
    getRunnersCategories() {
      axios.get("runners/categories").then((response) => {
        //console.log("Get Runner Cat : " + JSON.stringify(response.data));
        this.runnerCategories = response.data;
      });
    },

    getRunnersRequest() {
      axios.get(`runners/requests/${this.runnerRequestId}`).then((response) => {
        //console.log("Get Runner Cat : " + JSON.stringify(response.data));
        this.runnerRequest = response.data;
        this.form = response.data;
      });
    },

    onSubmit()
    {
       if (this.runnerRequestId) {
         this.updateRunnerRequest();
       } else {
          this.createRunnerRequest();
       }
    },

    updateRunnerRequest()
    {
      this.isLoading = true;
      axios.put(`runners/requests/${this.runnerRequestId}`, this.form).then((response) => {
        this.isLoading = false;
        if (response.status === 200 || response.status === 201) {
          this.$toasted.show("Request successfully updated. ");
          this.runnerRequest.description = "";
          this.runnerRequest.runner_category_id = "";
          this.handleSuccess()

        }
      }).catch((error) => {
        this.handleErrors(error, 'Updating runner request failed, please try again')
      });
    },

    createRunnerRequest()
    {
      return console.log("form", this.form);
      
      this.isLoading = true;
      axios.post("runners/requests", this.form).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.$toasted.success("Request successfully created. ");
          this.form.description = "";
          this.form.category_id = "";
          this.handleSuccess()
        }
      }).catch((error) => {
        this.handleErrors(error, 'Saving failed, please try again');
      });
    },

    handleSuccess() {
      this.isLoading = false;
      setTimeout(() => {
        this.$router.push({ name: "my_runner_requests" });
      }, 1000);
    },

    handleErrors(error, message) {
      if (error.response.status === 422) {
        if (message) {
          this.$toasted.error(message);
        }
        this.errors = error.response.data.errors
      } else {
        this.message = error.backendErrors[0]
      }
      this.isError = true
      this.isLoading = false;
    }

  },
}
</script>

<style scoped>

</style>