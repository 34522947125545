<template>
  <div class="">
    <h3>Edit Runner Request</h3>
    <RunnerRequestForm :runner-request-id="$route.params.id"></RunnerRequestForm>
  </div>
</template>

<script>
import RunnerRequestForm from "../../../../components/Forms/RunnerRequestForm";
export default {
  name: "EditRunnerRequest",
  components: {RunnerRequestForm},
};
</script>
